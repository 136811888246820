<template>
  <div class="activity-info-wrapper">
    <rz-request-state :state="state" />
    <div
      class="usage-report admin-screen-content activity-info"
      v-if="state.status.isLoaded"
    >
      <div class="item-list-row item-list">
        <div class="item-list-container">
          <h2>Activity Info</h2>
          <ActivityTable :projects="activityReport.projects"/>
          <div
            class="filter-pagination"
          >
            <div
              class="arrow left"
              :class="[isPrevPageExist() ? null : 'disable']"
              @click="prevPage()"
            ></div>
            <div class="page-number">{{ activePage.page }}</div>
            <div
              class="arrow right"
              :class="[isNextPageExist() ? null : 'disable']"
              @click="nextPage()"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  toRef,
  ref,
  reactive,
  onMounted,
} from 'vue';
import {
  ProjectList,
} from '@/store/modules/admin';
import { RequestState, RequestStateStatus } from '@/api/core';
import ActivityTable from './ActivityTable.vue';

export default {
  props: {
    project: Object,
  },

  components: {
    ActivityTable,
  },

  setup(props) {
    const project = toRef(props, 'project');
    const projectKey = project.value.key;
    const activityReport = ref({});
    let isLastPage = false;
    const pageLimit = 20;
    const activePage = reactive({
      page: 1,
      batchNo: 0,
    });

    const state = ref(new RequestState());

    const isPrevPageExist = () => {
      if (activePage.page === 1) {
        return false;
      }
      return true;
    };

    const isNextPageExist = () => {
      if (activityReport.value.cursor.total !== pageLimit) {
        return false;
      }
      return !isLastPage;
    };

    const isLastBlog = () => {
      const params = {
        last: activePage.batchNo,
      };
      ProjectList.activityReport(projectKey, params).then((nextPage) => {
        if (nextPage.data.data.projects.length) {
          isLastPage = false;
        } else {
          isLastPage = true;
        }
        isPrevPageExist();
        isNextPageExist();
      });
    };

    const ProjectActivityReport = (params) => {
      state.value.status = RequestStateStatus.Loading;
      return ProjectList.activityReport(projectKey, params).then((resp) => {
        activityReport.value = resp.data.data;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      }).then(() => {
        if (activityReport.value.cursor.total === pageLimit) {
          isLastBlog();
        } else {
          isLastPage = true;
        }
      });
    };
    const prevPage = () => {
      if (activePage.page === 1) {
        return;
      }
      activePage.page -= 1;
      const params = {};
      activePage.batchNo -= pageLimit;
      params.last = activePage.batchNo;
      ProjectActivityReport(params);
    };
    const nextPage = () => {
      if (isLastPage) {
        return;
      }
      activePage.page += 1;
      const params = {};
      activePage.batchNo += pageLimit;
      params.last = activePage.batchNo;
      ProjectActivityReport(params);
    };

    onMounted(() => {
      ProjectActivityReport();
    });

    return {
      state,
      ProjectActivityReport,
      activityReport,
      projectKey,
      activePage,
      isLastPage,
      prevPage,
      nextPage,
      pageLimit,
      isPrevPageExist,
      isNextPageExist,
    };
  },
};
</script>
<style lang="scss" scoped>
.activity-info-wrapper {
  width: 100%;
  .activity-info {
    h2 {
      font-size: size(18);
      font-weight: 600;
      padding: size(20) 0;
      text-align: center;
      text-transform: uppercase;
      background-color: var(--admin--color-level4);
      border-radius: var(--admin--default-radius);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0;
    }
    .item-list-container {
      width: 100%;
    }
    .filter-pagination {
      display: flex;
      justify-content: center;
      margin-bottom: size(50);
      .arrow {
        border-radius: size(5);
        border: size(1) solid var(--global--primary--Color);
        cursor: pointer;
        display: inline-block;
        &:after {
          content: '';
          border: solid var(--global--white--Color);
          border-width: 0 size(2) size(2) 0;
          display: inline-block;
          padding: size(3);
          vertical-align: middle;
        }
        &.left {
          padding: size(10) size(12) size(10) size(14);
          &:after {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }
        }
        &.right {
          padding: size(10) size(14) size(10) size(12);
          &:after {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }
        }
        &:hover {
          @include jump-up-animation;
        }
        &.disable {
          border: size(1) solid var(--global--gray--Color);
          cursor: not-allowed;
          &:after {
            border: solid var(--global--gray--Color);
            border-width: 0 size(2) size(2) 0;
          }
          &:hover {
            animation: none;
          }
        }
      }
      .page-number {
        margin: auto size(20);
      }
    }
  }
}
</style>
