<template>
  <div class="preview-box">
    <div class="preview-box-container">
      <div class="preview-box-title">
        <h2>Actions</h2>
      </div>
      <div class="preview-box-action-container">
        <div
          class="preview-box-content"
          v-if="adminInvoice"
        >
          <dl>
            <dt>Project Key</dt>
            <dd class="text-style codes">{{adminInvoice.project.key}}</dd>
          </dl>
          <dl>
            <dt>Project Status</dt>
            <dd class="text-style codes">{{adminInvoice.project.status}}</dd>
          </dl>
          <dl v-if="adminInvoice.status && adminInvoice.status.value">
            <dt>Status</dt>
            <dd class="text-style codes">{{adminInvoice.status.value}}</dd>
          </dl>
          <dl>
            <dt>Notes</dt>
            <dd class="text-style codes">{{adminInvoice.private_notes}}</dd>
          </dl>
          <dl>
            <dt>Email sent</dt>
            <dd class="text-style codes">{{adminInvoice.email_sent  ? 'Yes': 'No'}}</dd>
          </dl>
          <dl>
            <dt>Invoice Approved</dt>
            <dd class="text-style codes">{{adminInvoice.is_verified ? 'Yes': 'No'}}</dd>
          </dl>
        </div>
        <div class="preview-box-action-block">
          <div class="preview-box-tools">
            <ConfirmView
              actionButtonText="Fullfill Invoice"
              :action="actionFullfillInvoice"
              @on-action-done="onActionDone"
            />
            <br/>
            <ConfirmView
              v-if="adminInvoice && !adminInvoice.status.isCancelledInvoice"
              actionButtonText="Cancel Invoice"
              :action="actionCancel"
              @on-action-done="onActionDone"
            />
            <br v-if="adminInvoice && !adminInvoice.status.isCancelledInvoice"/>
            <ConfirmView
              v-if="adminInvoice && !adminInvoice.is_verified"
              actionButtonText="Approve Invoice"
              :action="actionApproveInvoice"
              @on-action-done="onActionDone"
            />
            <br v-if="adminInvoice && !adminInvoice.is_verified"/>
            <ConfirmView
              actionButtonText="Add Private Notes"
              title="Notes"
              :action="actionPrivateNotes"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <textarea
                  rows="5"
                  v-model="actionPrivateNotes.model"
                />
              </template>
            </ConfirmView>
            <br/>
            <ConfirmView
              actionButtonText="Send Invoice Email"
              :action="actionSendInvoice"
              @on-action-done="onActionDone"
            />
          </div>
        </div>
        <div class="preview-box-action-block">
          <div class="preview-box-tools">
            <ConfirmView
              actionButtonText="Add Cash Transaction"
              title="Add Cash Transaction"
              :action="actionAddCash"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <br/>
                <h2>Amount</h2>
                  <input class="currency-type" type="text"  :value="invoice.currency.toUpperCase()" disabled/>
                  <input class="currency-value" type="number" v-model="actionAddCash.amount"/>
                <h2>Currency - {{ invoice.currency.toUpperCase() }}</h2>
                <h2>
                  Visible to Customer &nbsp;
                  <input
                    type="checkbox"
                    name="roundoff-visible-false"
                    value="false"
                    v-model="actionAddCash.visibleToCustomer"
                  />
                </h2>
                <h2>Transaction Date</h2>
                <div class="transaction-date" @click="handleTogglerButton()">
                  <input
                    type="text"
                    v-model="actionAddCash.transactionDate"
                  />
                  <svg class="calendar-svg">
                    <use v-bind="{'xlink:href':'#calender-icon'}"></use>
                  </svg>
                </div>
                <div class="date-picker">
                  <template v-if="state.calendarTogglebutton">
                    <DatePicker
                      @dateselector="actionAddCash.transactionDate=$event"
                      @calendartoggler="handleTogglerButton()"
                    />
                  </template>
                </div>
                <h2>Reference no</h2>
                <input
                  type="text"
                  v-model="actionAddCash.referenceNumber"
                />
                <h2>Payment medium &nbsp;</h2>
                <select v-model="actionAddCash.paymentMedium">
                  <option disabled value="">Please select one</option>
                  <option
                    v-for="group in PaymentMedium.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.key}}</option>
                </select>
                <h2>Payment gateway &nbsp;</h2>
                <select v-model="actionAddCash.paymentGateway">
                  <option disabled value="">Please select one</option>
                  <option
                    v-for="group in PaymentGateway.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.key}}</option>
                </select>
                <h2>Public Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddCash.notes"
                />
                <h2>Private Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddCash.privateNotes"
                />
              </template>
            </ConfirmView>
            <br/>
            <ConfirmView
              actionButtonText="Add Credit Transaction"
              title="Add Credit Transaction"
              :action="actionAddCredit"
              :value="actionAddCredit"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <br/>
                <h2>Amount</h2>
                  <input class="currency-type" type="text"  :value="invoice.currency.toUpperCase()" disabled/>
                  <input class="currency-value" type="number" v-model="actionAddCredit.amount"/>
                <h2>Currency - {{ invoice.currency.toUpperCase() }}</h2>
                <h2>Transaction for:</h2>
                <select v-model="actionAddCredit.transactionFor">
                  <option disabled value="">Please select one</option>
                  <option
                    v-for="group in TransactionFor.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.label}}</option>
                </select>
                <h2>
                  Visible to Customer &nbsp;
                  <input
                    type="checkbox"
                    name="roundoff-visible-false"
                    v-model="actionAddCredit.visibleToCustomer"
                  />
                </h2>
                <h2>Transaction Date</h2>
                <div class="transaction-date" @click="handleTogglerButton()">
                  <input
                    type="text"
                    v-model="actionAddCredit.transactionDate"
                  />
                  <svg class="calendar-svg">
                    <use v-bind="{'xlink:href':'#calender-icon'}"></use>
                  </svg>
                </div>
                <div class="date-picker">
                  <template v-if="state.calendarTogglebutton">
                    <DatePicker
                      @dateselector="actionAddCredit.transactionDate=$event"
                      @calendartoggler="handleTogglerButton()"
                    />
                  </template>
                </div>
                <h2>
                  Update balance &nbsp;
                  <input
                    type="checkbox"
                    name="update-balance"
                    v-model="actionAddCredit.isWalletTransaction"
                  />
                </h2>
                <h2>Public Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddCredit.notes"
                />
                <h2>Private Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddCredit.privateNotes"
                />
              </template>
            </ConfirmView>
            <br/>
            <ConfirmView
              actionButtonText="Add TDS Transaction"
              title="Add TDS Transaction"
              :action="actionAddTDS"
              @on-action-done="onActionDone"
              :value="actionAddTDS"
            >
              <template v-slot:confirm>
                <br/>
                <h2>Amount</h2>
                  <input class="currency-type" type="text"  :value="invoice.currency.toUpperCase()" disabled/>
                  <input class="currency-value" type="number" v-model="actionAddTDS.amount"/>
                <h2>Currency - {{ invoice.currency.toUpperCase() }}</h2>
                <h2>
                  Visible to Customer &nbsp;
                  <input
                    type="checkbox"
                    name="tds-cutomer-visibility"
                    v-model="actionAddTDS.visibleToCustomer"
                  />
                </h2>
                <h2>Transaction Date</h2>
                <div class="transaction-date" @click="handleTogglerButton()">
                  <input
                    type="text"
                    v-model="actionAddTDS.transactionDate"
                  />
                  <svg class="calendar-svg">
                    <use v-bind="{'xlink:href':'#calender-icon'}"></use>
                  </svg>
                </div>
                <div class="date-picker">
                  <template v-if="state.calendarTogglebutton">
                    <DatePicker
                      @dateselector="actionAddTDS.transactionDate=$event"
                      @calendartoggler="handleTogglerButton()"
                    />
                  </template>
                </div>
                <h2>
                  TDS Tracking Verified &nbsp;
                  <input
                    type="checkbox"
                    name="tds-verified"
                    v-model="actionAddTDS.tdsTrackingVerified"
                  />
                </h2>
                <h2>Public Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddTDS.notes"
                />
                <h2>Private Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddTDS.privateNotes"
                />
              </template>
            </ConfirmView>
            <br/>
            <!-- <ConfirmView
              actionButtonText="Add TDS Return"
              title="Add TDS Return"
              :value="actionAddTDSReturn"
              :action="actionAddTDSReturn"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <br/>
                <h2>Amount</h2>
                  <input class="currency-type" type="text"  :value="invoice.currency.toUpperCase()" disabled/>
                  <input class="currency-value" type="number" v-model="actionAddTDSReturn.amount"/>
                <h2>Currency - {{ invoice.currency.toUpperCase() }}</h2>
                <h2>Transaction Date</h2>
                 <div class="transaction-date" @click="handleTogglerButton()">
                  <input
                    type="text"
                    v-model="actionAddTDSReturn.transactionDate"
                  />
                  <svg class="calendar-svg">
                    <use v-bind="{'xlink:href':'#calender-icon'}"></use>
                  </svg>
                </div>
                <div class="date-picker">
                  <template v-if="state.calendarTogglebutton">
                    <DatePicker
                      @dateselector="actionAddTDSReturn.transactionDate=$event"
                      @calendartoggler="handleTogglerButton()"
                    />
                  </template>
                </div>
                <h2>Public Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddTDSReturn.notes"
                />
                <h2>Private Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddTDSReturn.privateNotes"
                />
              </template>
            </ConfirmView>
            <br/>
            <ConfirmView
              actionButtonText="Add Roundoff Transaction"
              title="Add Roundoff Transaction"
              :action="actionAddRoundOff"
              :value="actionAddRoundOff"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <br/>
                <h2>Amount</h2>
                  <input class="currency-type" type="text"  :value="invoice.currency.toUpperCase()" disabled/>
                  <input class="currency-value" type="number" v-model="actionAddRoundOff.amount"/>
                <h2>Currency - {{ invoice.currency.toUpperCase() }}</h2>
                <h2>
                  Visible to Customer &nbsp;
                  <input
                    type="checkbox"
                    name="roundoff-visible-false"
                    value="false"
                    v-model="actionAddRoundOff.visibleToCustomer"
                  />
                </h2>
                <h2>Transaction Date</h2>
                 <div class="transaction-date" @click="handleTogglerButton()">
                  <input
                    type="text"
                    v-model="actionAddRoundOff.transactionDate"
                  />
                  <svg class="calendar-svg">
                    <use v-bind="{'xlink:href':'#calender-icon'}"></use>
                  </svg>
                </div>
                <div class="date-picker">
                  <template v-if="state.calendarTogglebutton">
                    <DatePicker
                      @dateselector="actionAddRoundOff.transactionDate=$event"
                      @calendartoggler="handleTogglerButton()"
                    />
                  </template>
                </div>
                <h2>Public Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddRoundOff.notes"
                />
                <h2>Private Notes</h2>
                <textarea
                  rows="5"
                  v-model="actionAddRoundOff.privateNotes"
                />
              </template>
            </ConfirmView> -->
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
import {
  toRef,
  ref,
  reactive,
} from 'vue';
import { useStore } from 'vuex';
import {
  InvoiceReport,
  AdminAction,
  TransactionFor,
  PaymentGateway,
  PaymentMedium,
} from '@/store/modules/admin';
import moment from 'moment';

import ConfirmView from '@/components/admin/ConfirmView.vue';
import DatePicker from '@/components/admin/DatePicker.vue';

export const dateFormat = (date) => {
  const formatedDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return moment(formatedDate).unix();
};

export default {
  props: {
    invoice: Object,
    adminInvoice: Object,
  },

  components: {
    ConfirmView,
    DatePicker,
  },

  setup(props) {
    const store = useStore();
    const invoice = toRef(props, 'invoice');
    const invoiceKey = invoice.value.key;
    const project = invoice.value.project.key;

    const actionCancel = ref(new AdminAction(
      () => InvoiceReport.action(invoiceKey, { action: 'cancel_invoice' }),
    ));

    const actionApproveInvoice = ref(new AdminAction(
      () => InvoiceReport.action(invoiceKey, { action: 'approve_invoice' }),
    ));

    const actionFullfillInvoice = ref(new AdminAction(
      () => InvoiceReport.action(invoiceKey, { action: 'process_for_fulfillment' }),
    ));

    const actionSendInvoice = ref(new AdminAction(
      () => InvoiceReport.action(invoiceKey, { action: 'send_invoice_email' }),
    ));

    const actionPrivateNotes = ref(new AdminAction(
      () => InvoiceReport.action(invoiceKey,
        { action: 'add_private_notes', private_notes: actionPrivateNotes.value.model }),
      null,
      { model: invoice.value.private_notes || '' },
    ));

    const state = reactive({
      calendarTogglebutton: false,
    });

    const handleTogglerButton = () => {
      state.calendarTogglebutton = !state.calendarTogglebutton;
    };

    const actionAddRoundOff = ref(
      new AdminAction(() => InvoiceReport.addTransaction({
        amount: parseFloat(actionAddRoundOff.value.amount),
        project,
        visible_to_customer: actionAddRoundOff.value.visibleToCustomer,
        request_type: 'roundoff',
        invoices: [invoiceKey],
        transaction_date: dateFormat(actionAddRoundOff.value.transactionDate),
        notes: actionAddRoundOff.value.notes,
        private_notes: actionAddRoundOff.value.privateNotes,
        return_invoice_response: true,
      })),
    );

    const actionAddTDSReturn = ref(
      new AdminAction(() => InvoiceReport.addTransaction({
        amount: parseFloat(actionAddTDSReturn.value.amount),
        project,
        request_type: 'tds_return',
        invoices: [invoiceKey],
        transaction_date: dateFormat(actionAddTDSReturn.value.transactionDate),
        tds_tracking_verified: true,
        notes: actionAddTDSReturn.value.notes,
        private_notes: actionAddTDSReturn.value.privateNotes,
        return_invoice_response: true,
        visible_to_customer: true,
      })),
    );

    const actionAddCredit = ref(
      new AdminAction(() => InvoiceReport.addTransaction({
        amount: parseFloat(actionAddCredit.value.amount),
        project,
        visible_to_customer: actionAddCredit.value.visibleToCustomer,
        request_type: 'credit',
        invoices: [invoiceKey],
        notes: actionAddCredit.value.notes,
        private_notes: actionAddCredit.value.privateNotes,
        transaction_date: dateFormat(actionAddCredit.value.transactionDate),
        is_wallet_transaction: actionAddCredit.value.isWalletTransaction,
        transaction_for: actionAddCredit.value.transactionFor,
        return_invoice_response: true,
      })),
    );

    const actionAddTDS = ref(
      new AdminAction(() => InvoiceReport.addTransaction({
        amount: parseFloat(actionAddTDS.value.amount),
        project,
        visible_to_customer: actionAddTDS.value.visibleToCustomer,
        tds_tracking_verified: actionAddTDS.value.tdsTrackingVerified,
        notes: actionAddTDS.value.notes,
        private_notes: actionAddTDS.value.privateNotes,
        request_type: 'tds',
        invoices: [invoiceKey],
        transaction_date: dateFormat(actionAddTDS.value.transactionDate),
        return_invoice_response: true,
      })),
    );

    const actionAddCash = ref(
      new AdminAction(() => InvoiceReport.addTransaction({
        amount: parseFloat(actionAddCash.value.amount),
        project,
        visible_to_customer: actionAddCash.value.visibleToCustomer,
        payment_gateway: actionAddCash.value.paymentGateway,
        payment_medium: actionAddCash.value.paymentMedium,
        reference_number: actionAddCash.value.referenceNumber,
        request_type: 'cash',
        invoices: [invoiceKey],
        notes: actionAddCash.value.notes,
        private_notes: actionAddCash.value.privateNotes,
        transaction_date: dateFormat(actionAddCash.value.transactionDate),
        return_invoice_response: true,
      })),
    );

    const onInvoiceUpdate = (updatedInvoice) => {
      store.commit('admin/updatePreviewInvoice', { invoice: updatedInvoice });
      store.commit('admin/updatePreviewAdminInvoice', { adminInvoice: updatedInvoice });
    };

    const resetForm = {
      notes: '',
      privateNotes: '',
      amount: '',
      visibleToCustomer: true,
      transactionDate: moment().format('DD/MM/YYYY'),
      tdsTrackingVerified: true,
      transactionFor: TransactionFor.FreeCredit,
      isWalletTransaction: true,
      paymentGateway: PaymentGateway.Card,
      paymentMedium: PaymentMedium.Stripe,
      referenceNumber: '',
    };

    const onActionDone = (context, updatedInvoice) => {
      onInvoiceUpdate(updatedInvoice);
      Object.assign(actionAddCash.value, resetForm);
      Object.assign(actionAddCredit.value, resetForm);
      Object.assign(actionAddTDS.value, resetForm);
      Object.assign(actionAddTDSReturn.value, resetForm);
      Object.assign(actionAddRoundOff.value, resetForm);
    };

    return {
      actionCancel,
      actionPrivateNotes,
      actionApproveInvoice,
      actionSendInvoice,
      onActionDone,
      actionAddRoundOff,
      actionAddTDSReturn,
      TransactionFor,
      PaymentGateway,
      PaymentMedium,
      actionAddTDS,
      actionAddCredit,
      actionAddCash,
      actionFullfillInvoice,
      handleTogglerButton,
      state,
    };
  },
};
</script>
