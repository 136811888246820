<template>
  <div class="preview-box">
    <div class="preview-box-container">
      <div class="preview-box-title">
        <h2>Trail</h2>
      </div>
      <div
        class="preview-box-content"
        v-if="trail"
      >
        <p>
          <label>Duration:</label>
          <br/>
          {{ $filters.utcLocalDate(trail.trail_start) }}
          <br/>
          {{ $filters.utcLocalDate(trail.trail_end) }}
        </p>
        <br />
        <p>
          <label>Approved by:</label>
          <br/>
          {{trail.trail_granted_by}}
        </p>
      </div>
      <div v-else>
        Trail not enabled!
      </div>
      <hr v-if="trail">
      <div
        v-if="trail"
        class="preview-box-tools"
      >
        <ConfirmView
          v-if="!trail.is_trail_ended"
          actionButtonText="Stop Trial"
          :action="actionTrialCancel"
          @on-action-done="onActionDone"
        >
        </ConfirmView>
        <ConfirmView
          actionButtonText="Extend Trail"
          title="How many Days?"
          :action="actionTrialActivate"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <input
              v-model="actionTrialActivate.model"
            />
          </template>
        </ConfirmView>
      </div>
    </div>
  </div>
</template>
<script>
import {
  toRef,
  ref,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import {
  ProjectList,
  AdminAction,
} from '@/store/modules/admin';

import ConfirmView from '@/components/admin/ConfirmView.vue';

export default {
  props: {
    project: Object,
  },

  components: {
    ConfirmView,
  },

  setup(props) {
    const store = useStore();
    const project = toRef(props, 'project');
    const projectKey = project.value.key;

    const actionTrialActivate = ref(new AdminAction(
      () => ProjectList.activateFreeTrail(projectKey, actionTrialActivate.value.model),
      null,
      { model: 7 },
    ));

    const actionTrialCancel = ref(new AdminAction(
      () => ProjectList.deactivateFreeTrail(projectKey),
    ));

    const onProjectUpdate = (updatedProject) => {
      store.commit('admin/updatePreviewProject', { project: updatedProject });
    };

    const onActionDone = (context, updatedProject) => {
      onProjectUpdate(updatedProject);
    };

    return {
      trail: computed(() => props.project.free_trail_detail),
      actionTrialActivate,
      actionTrialCancel,
      onActionDone,
    };
  },
};
</script>
