<template>
  <div class="quick-preview">
    <div class="quick-preview-bg">
      <div class="quick-preview-container">
        <div
          class="col first-col preview-col-list"
          v-if="!hideLeft"
        >
          <slot name="left"></slot>
        </div>
        <div class="col second-col preview-col-content">
          <div class="preview-title">
            <slot name="title"></slot>
          </div>
          <div class="preview-content">
            <slot name="content"></slot>
          </div>
        </div>
        <div class="col third-col preview-col-actions" v-if="false">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hideLeft: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
