<template>
  <div class="admin-project-preview">
    <rz-request-state :state="state" />
    <div
      v-if="state.status.isLoaded"
    >
      <div class="preview-boxes">
        <ProjectPreviewBillingView
          :project="project"
          v-if="project.hasBilling"
        />
        <ProjectPreviewUserView :project="project" />
        <ProjectPreviewTrialView
          :project="project"
          v-if="project.free_trail_detail"
        />
        <ProjectPreviewInfoView :project="project" />
        <ProjectActivityInfoView :project="project" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  onMounted,
  watch,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
} from '@/store/modules/admin';

import ProjectActivityInfoView from './ProjectActivityInfo.vue';
import ProjectPreviewBillingView from './ProjectPreviewBilling.vue';
import ProjectPreviewUserView from './ProjectPreviewUser.vue';
import ProjectPreviewTrialView from './ProjectPreviewTrial.vue';
import ProjectPreviewInfoView from './ProjectPreviewInfo.vue';

export default {
  props: {
    projectKey: String,
  },

  components: {
    ProjectPreviewBillingView,
    ProjectPreviewUserView,
    ProjectPreviewTrialView,
    ProjectPreviewInfoView,
    ProjectActivityInfoView,
  },

  setup(props) {
    const store = useStore();
    // const project = ref({});
    const state = ref(new RequestState());

    const fetchProject = () => {
      state.value.status = RequestStateStatus.Loading;
      ProjectList.fetchProject(props.projectKey).then((projectResponse) => {
        if (projectResponse) {
          store.commit('admin/updatePreviewProject', { project: projectResponse });
          state.value.status = RequestStateStatus.Loaded;
        }
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    watch(() => props.projectKey, () => {
      fetchProject();
    });

    onMounted(() => {
      fetchProject();
    });

    return {
      state,
      project: computed(() => store.state.admin.previewProject),
    };
  },
};
</script>
