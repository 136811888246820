<template>
  <div class="preview-box">
    <div class="preview-box-container">
      <div class="preview-box-title">
        <h2>Billing</h2>
      </div>
      <div
        class="preview-box-content"
        v-if="project.hasBilling"
      >
        <p>{{project.billing.contact_name}}</p>
        <p>{{project.billing.contact_email}}</p>
        <p class="text-style w28 add-margin">
          {{ project.billing.address}}
          <br />
          {{ project.billing.city }}, {{ project.billing.state_name }}
          <br />
          {{ project.billing.country }} - {{ project.billing.zip_code }}
          <br />
        </p>
        <p v-if="project.billing.company_name" class="text-style w28 add-margin">
          <label>Company:</label>
          {{project.billing.company_name}}
        </p>
        <p class="add-margin" v-if="project.billing.gst_number">
          <label>GST:</label>
          {{project.billing.gst_number}}
        </p>
        <p class="text-style uppercase add-margin">
          {{project.billing.preferred_payment_medium || ' No Medium'}}
          -
          {{project.billing.preferred_payment_gateway || ' No Gateway'}}
        </p>
        <p class="add-margin">
          {{project.billing.country}},
          {{project.billing.billing_currency || 'No Currency'}}
        </p>
        <p v-if="project.billing_method" class="add-margin">
          <label class="label-style">Billing Method: </label>
          {{project.billing_method}}
          <ConfirmView
            actionButtonText="Change"
            actionButtonClass="btn btn-sm text"
            :action="actionChangeBillingMethod"
            @on-action-done="onActionDone"
            >
            <template v-slot:confirm>
              <select v-model="actionChangeBillingMethod.model">
                <option
                  v-for="group in BillGroup.allItems()"
                  :key="group.value"
                  :value="group.value"
                >{{group.key}}</option>
              </select>
            </template>
          </ConfirmView>
        </p>
        <p v-if="project.custom_billing_limit" class="add-margin">
          <label class="label-style">Billing Limit: </label>
          {{project.custom_billing_limit}}
        </p>
        <p v-if="project.billing.billing_currency" class="add-margin">
          <label class="label-style">Billing Currency: </label>
          {{project.billing.billing_currency}}
        </p>
        <p v-if="project.disable_api_access_termination" class="add-margin">
          <label class="label-style">Extended Access Termination: </label>
          {{$filters.formatDate(project.disable_api_access_termination_until)}}
        </p>
        <p class="add-margin" v-if="project.lifetime_discount_attached_on">
          <label class="label-style">Lifetime discount attached on: </label>
          {{$filters.formatDate(project.lifetime_discount_attached_on)}}
        </p>
        <p class="add-margin" v-if="project.lifetime_discount_attached_on">
          <label class="label-style">Lifetime discount percentage: </label>
          {{project.lifetime_discount_percentage}}%
        </p>
        <p class="add-margin" v-if="project.minimum_lifetime_discount_amount">
          <label class="label-style">Minimum discount amount: </label>
          {{project.minimum_lifetime_discount_amount}}
        </p>
      </div>
      <div v-else>
        Billing Profile Missing!
      </div>
      <hr v-if="project.hasBilling">
      <div
        v-if="project.hasBilling"
        class="preview-box-tools"
      >
        <ConfirmView
          v-if="project.billing.is_verified"
          actionButtonText="Deactivate Billing"
          :action="actionBillingDeactivate"
          @on-action-done="onActionDone"
        />
        <ConfirmView
          actionButtonText="Mark as Verified"
          :action="actionBillingActivate"
          @on-action-done="onActionDone"
        />
        <ConfirmView
          v-if="!project.custom_billing_limit &&
          project.billing_method !== 'prepay'"
          actionButtonText="Set Billing Limit"
          title="Set Limit"
          :action="actionSetProjectLimit"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <input
              type="number"
              v-model="actionSetProjectLimit.model"
            />
          </template>
        </ConfirmView>
        <ConfirmView
          v-else-if="project.custom_billing_limit
          && project.billing_method !== 'prepay'"
          actionButtonText="Edit Billing Limit"
          title="Set Limit"
          :action="actionSetProjectLimit"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <input
              type="number"
              v-model="actionSetProjectLimit.model"
            />
          </template>
        </ConfirmView>
        <ConfirmView
          actionButtonText="Add Free Credits"
          title="Add Credits"
          :action="actionAddFreeCredits"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <input
              type="number"
              v-model="actionAddFreeCredits.model"
            />
            <h2>Notes</h2>
            <textarea
              rows="5"
              v-model="actionAddFreeCredits.notes"
            />
          </template>
        </ConfirmView>
        <ConfirmView
          actionButtonText="Extend Access Termination"
          title="Extend Access Termination"
          :action="actionExtendAccessTermination"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <div class="transaction-date" @click="handleTogglerButton()">
              <input
                type="text"
                v-model="actionExtendAccessTermination.model"
                placeholder="dd/mm/yyyy"
              />
              <svg class="calendar-svg">
                <use v-bind="{'xlink:href':'#calender-icon'}"></use>
              </svg>
            </div>
            <div class="date-picker">
              <template v-if="state.calendarTogglebutton">
                <DatePicker
                  @dateselector="actionExtendAccessTermination.model=$event"
                  @calendartoggler="handleTogglerButton()"
                />
              </template>
            </div>
          </template>
        </ConfirmView>
         <ConfirmView
          actionButtonText="Add Lifetime Discount"
          title="Lifetime Discount %"
          :action="actionAddLifetimeDiscount"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <input
              type="number"
              v-model="actionAddLifetimeDiscount.model"
            />
            <h2>Minimum Amount</h2>
            <input
              type="number"
              v-model="actionAddLifetimeDiscount.amount"
            />
          </template>
        </ConfirmView>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  toRef,
  reactive,
} from 'vue';
import { useStore } from 'vuex';
import {
  ProjectList,
  AdminAction,
  BillGroup,
} from '@/store/modules/admin';

import ConfirmView from '@/components/admin/ConfirmView.vue';
import DatePicker from '@/components/admin/DatePicker.vue';
import { dateFormat } from '@/components/admin/InvoicePreviewActions.vue';

export default {
  props: {
    project: Object,
  },
  components: {
    ConfirmView,
    DatePicker,
  },
  setup(props) {
    const store = useStore();
    const project = toRef(props, 'project');
    const projectKey = project.value.key;

    const actionBillingDeactivate = ref(new AdminAction(
      () => ProjectList.unverifyBilling(projectKey),
    ));

    const actionBillingActivate = ref(new AdminAction(
      () => ProjectList.verifyBilling(projectKey),
    ));

    const actionChangeBillingMethod = ref(new AdminAction(
      () => ProjectList.changeBillingMethod(projectKey, actionChangeBillingMethod.value.model),
      null,
      { model: project.value.group },
    ));

    const actionSetProjectLimit = ref(new AdminAction(
      () => ProjectList.editProjectLimit(projectKey, parseFloat(actionSetProjectLimit.value.model)),
      null,
    ));

    const actionAddFreeCredits = ref(new AdminAction(
      () => ProjectList.addFreeCredits(projectKey, actionAddFreeCredits.value.model,
        actionAddFreeCredits.value.notes),
      null,
    ));

    const actionExtendAccessTermination = ref(new AdminAction(
      () => ProjectList.extendAccessTermination(projectKey, dateFormat(actionExtendAccessTermination.value.model)),
      null,
    ));

    const actionAddLifetimeDiscount = ref(new AdminAction(
      () => ProjectList.addLifetimeDiscount(projectKey, actionAddLifetimeDiscount.value.model,
        actionAddLifetimeDiscount.value.amount),
      null,
    ));

    const state = reactive({
      calendarTogglebutton: false,
    });

    const handleTogglerButton = () => {
      state.calendarTogglebutton = !state.calendarTogglebutton;
    };

    const onProjectUpdate = (updatedProject) => {
      store.commit('admin/updatePreviewProject', { project: updatedProject });
    };

    const onActionDone = (context, updatedProject) => {
      onProjectUpdate(updatedProject);
    };

    return {
      actionBillingActivate,
      actionBillingDeactivate,
      actionChangeBillingMethod,
      actionSetProjectLimit,
      actionAddFreeCredits,
      actionAddLifetimeDiscount,
      onActionDone,
      BillGroup,
      actionExtendAccessTermination,
      state,
      handleTogglerButton,
    };
  },
};
</script>
