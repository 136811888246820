<template>
  <div class="preview-box">
    <div class="preview-box-container">
      <div class="preview-box-title">
        <h2>Owner</h2>
      </div>
      <div
        class="preview-box-content"
        v-if="project.created_by.email"
      >
        <p>{{project.created_by.name}}</p>
        <p>{{project.created_by.email}}</p>
        <br />
        <p>
          <label>Email Verified: <span>{{project.created_by.email_verified ? '✅': 'No'}}</span></label>
        </p>
        <p>
          <label>Has Legacy Access: <span>{{haveLegacyAccess ? '✅': 'No'}}</span></label>
        </p>
      </div>
      <div v-else>
        Owner Profile Missing!
      </div>
    </div>
  </div>
</template>
<script>
import {
  computed,
} from 'vue';

export default {
  props: {
    project: Object,
  },

  setup(props) {
    const haveLegacyAccess = computed(() => {
      const createdBy = props.project.created_by;
      return createdBy.properties && createdBy.properties.show_v2_console;
    });
    return {
      haveLegacyAccess,
    };
  },
};
</script>
