<template>
  <div class="preview-box">
    <div class="preview-box-container">
      <div class="preview-box-title">
        <h2>Info</h2>
      </div>
      <div
        class="preview-box-content"
      >
        <dl>
          <dt>Project Key</dt>
          <dd class="text-style codes">{{project.key}}</dd>
          <dt>License</dt>
          <dd v-if="project.project_license.name">{{project.project_license.name}}</dd>
          <template v-if="project.active_api_keys && project.active_api_keys.length">
          <dt>API Key</dt>
          <dd class="text-style codes">{{project.active_api_keys[0].api_key}}</dd>
          </template>
          <dt>Group</dt>
          <dd>
            <span>{{project.group || 'MISSING'}}</span>
            <ConfirmView
              actionButtonText="Change"
              actionButtonClass="btn btn-sm text"
              :action="actionChangeGroup"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <select v-model="actionChangeGroup.model">
                  <option
                    v-for="group in ProjectGroup.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.key}}</option>
                </select>
              </template>
            </ConfirmView>
          </dd>
          <dt>Pending Status</dt>
          <dd
            :class="[project.pending_status &&
              project.pending_status === 'payment_pending' ? 'pending-status': '']"
          >
            <span  v-if="project.pending_status &&
              project.pending_status === 'payment_pending'">
              Payment Pending
              <svg>
                <use v-bind="{ 'xlink:href': '#payment-pending-icon' }" />
              </svg>
            </span>
            <span v-else-if="project.pending_status &&
            project.pending_status !== 'payment_pending'">
              {{project.pending_status}}
            </span>
            <span v-else> - </span>
             <ConfirmView
              actionButtonText="Change"
              actionButtonClass="btn btn-sm text"
              :action="actionChangePendingStatus"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <select v-model="actionChangePendingStatus.model">
                  <option :value="'payment_pending'">Payment pending</option>
                  <option :value="null">null</option>
                </select>
              </template>
            </ConfirmView>
          </dd>
          <dt>Status</dt>
          <dd>{{project.status && project.status.key ? project.status.key : '-'}}
            <ConfirmView
              actionButtonText="Change"
              actionButtonClass="btn btn-sm text"
              :action="actionChangeProjectStatus"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <select v-model="actionChangeProjectStatus.model">
                  <option
                    v-for="group in ProjectStatusGroup.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.value}}</option>
                </select>
              </template>
            </ConfirmView>
          </dd>
          <dt>Data Scope</dt>
          <dd>{{project.data_scope ? project.data_scope : '-'}}
            <ConfirmView
              actionButtonText="Change"
              actionButtonClass="btn btn-sm text"
              :action="actionChangeDataScope"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <select v-model="actionChangeDataScope.model">
                  <option
                    v-for="group in DataScope.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.value}}</option>
                </select>
              </template>
            </ConfirmView>
          </dd>
          <dt>Project Notes</dt>
          <dd v-html="project.notes"
            v-if="project.notes_list && project.notes_list.length <= 1">
          </dd>
          <ul class="notes-list" v-else>
            <li v-html="list" v-for="list in project.notes_list" :key="list.key">
            </li>
          </ul>
          <dt>Private Notes</dt>
          <dd v-html="project.private_notes"
            v-if="project.private_notes_list && project.private_notes_list.length <= 1">
          </dd>
          <ul class="notes-list" v-else>
            <li v-html="list" v-for="list in project.private_notes_list" :key="list.key">
            </li>
          </ul>
          <dt>Project Sales Process Step</dt>
          <dd>{{project.sales_process_step >= 0 ? project.sales_process_step : ''}}
            -
            {{project.sales_process_step_name ? project.sales_process_step_name : ''}}
            <ConfirmView
              actionButtonText="Change"
              actionButtonClass="btn btn-sm text"
              :action="actionChangeSalesProcessStep"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <select v-model="actionChangeSalesProcessStep.model">
                  <option
                    v-for="group in SalesProcessStep.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.value}}-{{group.label}}</option>
                </select>
              </template>
            </ConfirmView>
          </dd>
          <dt>Project Sales Process Notes</dt>
          <dd v-html="project.sales_process_notes"
            v-if="project.sales_process_notes_list && project.sales_process_notes_list.length <= 1">
          </dd>
          <ul class="notes-list" v-else>
            <li v-html="list" v-for="list in project.sales_process_notes_list" :key="list.key">
            </li>
          </ul>
        </dl>
      </div>
      <hr>
      <div
        class="preview-box-tools"
      >
        <ConfirmView
          v-if="project.group !== ProjectGroup.Test.value"
          actionButtonText="Mark as Test"
          :action="actionMarkAsTest"
          @on-action-done="onActionDone"
        >
        </ConfirmView>
        <ConfirmView
          actionButtonText="Add Project Notes"
          title="Notes"
          :action="actionAddNotes"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <textarea
              rows="5"
              v-model="actionAddNotes.model"
            />
          </template>
        </ConfirmView>
        <ConfirmView
          actionButtonText="Add Private Notes"
          title="Private Notes"
          :action="actionAddPrivateNotes"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <textarea
              rows="5"
              v-model="actionAddPrivateNotes.model"
            />
          </template>
        </ConfirmView>
        <ConfirmView
          actionButtonText="Add Project Sales Process Notes"
          title="Project Sales Process Notes"
          :action="actionAddSalesProcessNotes"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <textarea
              rows="5"
              v-model="actionAddSalesProcessNotes.model"
            />
          </template>
        </ConfirmView>
        <ConfirmView
          v-if="project.group !== ProjectGroup.Trail.value"
          actionButtonText="Enable Trial"
          title="How many Days?"
          :action="actionTrialActivate"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <input
              v-model="actionTrialActivate.model"
            />
          </template>
        </ConfirmView>
        <br/>
        <hr />
        <ConfirmView
          v-if="!project.isSuspended"
          :action="actionMarkAsTestAndDelete"
          @on-action-done="onActionDone"
        >
          <template v-slot:normal>
            <p>Mark the Project as Test and Delete</p>
            <button
              class="btn btn-sm danger"
              @click="actionMarkAsTestAndDelete.askConfirm"
            >Mark as Test &amp; Delete</button>
          </template>
        </ConfirmView>
        <br/>
        <hr />
        <ConfirmView
          v-if="!project.isSuspended"
          :action="actionDelete"
          @on-action-done="onActionDone"
        >
          <template v-slot:normal>
            <p>Disabled access and Delete</p>
            <button
              class="btn btn-sm danger"
              @click="actionDelete.askConfirm"
            >Delete</button>
          </template>
        </ConfirmView>
      </div>
    </div>
  </div>
</template>
<script>
import {
  toRef,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  ProjectList,
  ProjectGroup,
  ProjectStatusGroup,
  DataScope,
  SalesProcessStep,
  AdminAction,
} from '@/store/modules/admin';

import ConfirmView from '@/components/admin/ConfirmView.vue';

export default {
  props: {
    project: Object,
  },

  components: {
    ConfirmView,
  },

  setup(props) {
    const store = useStore();
    const project = toRef(props, 'project');
    const projectKey = project.value.key;

    const actionChangeGroup = ref(new AdminAction(
      () => ProjectList.changeProjectGroups(projectKey, actionChangeGroup.value.model),
      null,
      { model: project.value.group || '' },
    ));

    const actionChangeProjectStatus = ref(new AdminAction(
      () => ProjectList.changeProjectStatusGroups(projectKey, actionChangeProjectStatus.value.model),
      null,
      { model: (project.value.status && project.value.status.key) || '' },
    ));

    const actionChangePendingStatus = ref(new AdminAction(
      () => ProjectList.changePendingStatusGroups(projectKey, actionChangePendingStatus.value.model),
      null,
      { model: project.value.pending_status || '' },
    ));

    const actionChangeDataScope = ref(new AdminAction(
      () => ProjectList.changeDataScopeGroups(projectKey, actionChangeDataScope.value.model),
      null,
      { model: project.value.data_scope || '' },
    ));

    const actionChangeSalesProcessStep = ref(new AdminAction(
      () => ProjectList.changeSalesProcessStep(projectKey, actionChangeSalesProcessStep.value.model),
      null,
      { model: project.value.sales_process_step || '' },
    ));

    const actionTrialActivate = ref(new AdminAction(
      () => ProjectList.activateFreeTrail(projectKey, actionTrialActivate.value.model),
      null,
      { model: 7 },
    ));

    const actionMarkAsTest = ref(new AdminAction(
      () => ProjectList.changeProjectGroups(projectKey, ProjectGroup.Test.value),
    ));

    const actionAddNotes = ref(new AdminAction(
      () => ProjectList.addNotes(projectKey,
        { action: 'add-notes', notes: actionAddNotes.value.model, project_key: projectKey }),
      null,
      { model: project.value.notes || '' },
    ));

    const actionAddPrivateNotes = ref(new AdminAction(
      () => ProjectList.addPrivateNotes(projectKey,
        { action: 'add-private-notes', private_notes: actionAddPrivateNotes.value.model, project_key: projectKey }),
      null,
      { model: project.value.private_notes || '' },
    ));

    const actionAddSalesProcessNotes = ref(new AdminAction(
      () => ProjectList.addSalesProcessNotes(projectKey,
        {
          sales_process_notes: actionAddSalesProcessNotes.value.model,
          project_key: projectKey,
        }),
      null,
      { model: project.value.sales_process_notes || '' },
    ));

    const actionDelete = ref(new AdminAction(
      () => ProjectList.deleteProject(projectKey),
    ));

    const actionMarkAsTestAndDelete = ref(new AdminAction(
      () => ProjectList.changeProjectGroups(
        projectKey, ProjectGroup.Test.value,
      ).then(() => ProjectList.deleteProject(projectKey, { val: true })),
    ));

    const onProjectUpdate = (updatedProject) => {
      store.commit('admin/updatePreviewProject', { project: updatedProject });
    };

    const onActionDone = (context, updatedProject) => {
      onProjectUpdate(updatedProject);
    };

    return {
      actionChangeGroup,
      actionChangeProjectStatus,
      actionChangePendingStatus,
      actionChangeDataScope,
      actionChangeSalesProcessStep,
      actionAddSalesProcessNotes,
      actionMarkAsTest,
      actionTrialActivate,
      actionMarkAsTestAndDelete,
      actionDelete,
      actionAddNotes,
      actionAddPrivateNotes,
      onActionDone,
      ProjectGroup,
      ProjectStatusGroup,
      DataScope,
      SalesProcessStep,
    };
  },
};
</script>
<style lang="scss" scoped>
  .notes-list {
    margin: size(8) 0 size(16);
    li {
      padding: size(6) 0;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: size(5);
        height: size(5);
        background: var(--global--white--Color);
        border-radius: 50%;
        margin-right: size(8);
      }
    }
  }
</style>
