<template>
  <div class="calendar">
    <div class="calendar__header">
      <span>{{ dateContext.format("MMMM") }} {{ year }}</span>
      <div class="calendar__header__header-action">
        <a @click="lastMonth">
          ⍗
        </a>
        <a @click="nextMonth">
          ⍐
        </a>
      </div>
    </div>
    <ul class="dates">
      <li class="days" v-for="day in days" :key="day">{{ day }}</li>
      <li v-for="blank in firstDayOfMonth" :key="blank" class="day"></li>
      <li
        v-for="date in daysInMonth"
        :key="date"
        @click="selectedDate(`${date}/${month}/${year}`)"
        class="day"
      >
        <span>{{ date }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      today: moment(),
      dateContext: moment(),
      days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    };
  },
  methods: {
    nextMonth() {
      this.dateContext = moment(this.dateContext).add(1, 'month');
    },
    lastMonth() {
      this.dateContext = moment(this.dateContext).subtract(1, 'month');
    },
    selectedDate(day) {
      this.$emit('dateselector', day);
      this.$emit('calendartoggler', false);
    },
  },
  computed: {
    year() {
      return this.dateContext.format('YYYY');
    },
    month() {
      return this.dateContext.format('MM');
    },
    daysInMonth() {
      return this.dateContext.daysInMonth();
    },
    currentDate() {
      return this.dateContext.get('date');
    },
    firstDayOfMonth() {
      const firstDay = moment(this.dateContext).subtract(
        this.currentDate - 1,
        'days',
      );
      return firstDay.weekday();
    },
    initialDate() {
      return this.today.get('date');
    },
    initialMonth() {
      return this.today.format('MM');
    },
    initialYear() {
      return this.today.format('YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  transform: translate3d(0, 0, 0, 0);
  width: 100%;
  background-color: var(--global--white--Color);
  position: absolute;
  z-index: 10;

  &__header {
    display: flex;
    padding: size(16);
    align-items: center;
    width: 100%;
    background-color: var(--global--white--Color);
    color: var(--admin--color-level2);

    &__header-action {
      padding-left: size(5);
      a {
        color: var(--global--link--Color)!important;
      }
    }
  }
}

a {
  margin: size(5);
  cursor: pointer;
}

.dates {
  display: flex;
  flex-wrap: wrap;
  color: var(--admin--text-level3);
}

.day {
  width: 14.286%;
  padding: size(10);
  text-align: center;
  cursor: pointer;
}

.days {
  width: 14.286%;
  text-align: center;
  padding: size(14);
  color: var(--admin--color-calendar-text);
  font-weight: bold;
}

.day-name {
  font-size: size(16);
  text-transform: uppercase;
  margin-bottom: size(5);
}

.day-number {
  font-size: size(24);
  letter-spacing: size(1.5);
}
</style>
