<template>
  <div
    class="admin-confirm-view"
    :class="[`rootstate-${confirmState.value}`]">
    <div
      class="confirm-view-content"
    >
      <div
        class="state-loading"
        v-if="confirmState.isLoading">
        <rz-request-state :state="state" />
        <slot name="loading"></slot>
      </div>
      <div
        class="state-loading"
        v-else-if="confirmState.isError">
        <rz-request-state :state="state" />
        <slot name="error"></slot>
      </div>
      <div
        class="state-confirm"
        v-else-if="confirmState.isAskConfirm"
      >
        <h2 v-if="title">{{title}}</h2>
        <p v-if="message">{{message}}</p>
        <slot name="confirm"></slot>
        <div class="tools">
          <button
            class="btn btn-sm"
            @click="doAction"
          >Confirm</button>
          <button
            class="btn btn-sm"
            @click="doCancel"
          >Close</button>
        </div>
      </div>
      <div
        class="state-completed"
        v-else-if="confirmState.isCompleted"
      >
        <slot name="completed">
          <span class="success">👍</span>
        </slot>
      </div>

      <div
        class="state-normal"
        v-if="showNormalState"
      >
        <button
          :class="[actionButtonClass]"
          v-if="actionButtonText"
          @click="doActionClick"
        >{{actionButtonText}}</button>
        <slot name="normal"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  watch,
  computed,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import { Enum } from '@/core/utils/enum';

const ConfirmState = new Enum({
  Normal: 'Normal',
  Loading: 'Loading',
  Error: 'Error',
  AskConfirm: 'AskConfirm',
  Completed: 'Completed',
});

export default {
  props: {
    action: Object,
    actionButtonText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Are you sure?',
    },
    actionButtonClass: {
      type: String,
      default: 'btn btn-sm',
    },
    message: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const state = ref(new RequestState());
    const confirmState = ref(ConfirmState.Normal);
    const showDone = ref(false);

    const doAction = () => {
      emit('on-action-loading', props.action);
      state.value.status = RequestStateStatus.Loading;
      confirmState.value = ConfirmState.Loading;

      showDone.value = false;
      props.action.fn().then((response) => {
        state.value.status = RequestStateStatus.Loaded;
        confirmState.value = ConfirmState.Completed;
        props.action.cancelAsk();
        showDone.value = true;
        emit('on-action-done', props.action, response);
      }).catch((err) => {
        confirmState.value = ConfirmState.Error;
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
        props.action.cancelAsk();
        emit('on-action-error', props.action, err);
      });
    };

    const doInit = () => {
      state.value.status = RequestStateStatus.NotInit;
      confirmState.value = ConfirmState.Normal;
    };

    const doCancel = () => {
      doInit();
      props.action.cancel();
      emit('cancel', props.action);
    };

    const doActionClick = () => {
      doInit();
      props.action.askConfirm();
      console.log('C', props.action.ask);
      emit('on-action-click', props.action);
    };

    watch(() => props.action.ask, () => {
      console.log('Changed', props.action.ask, confirmState.value);
      if (props.action.ask && state.value.status.isLoaded) {
        doInit();
      } else if (props.action.ask && confirmState.value.isNormal) {
        confirmState.value = ConfirmState.AskConfirm;
      }
    });

    const showNormalState = computed(() => {
      if (confirmState.value.isLoading) {
        return false;
      }

      if (confirmState.value.isAskConfirm) {
        return false;
      }

      return true;
    });

    return {
      confirmState,
      doAction,
      doCancel,
      state,
      showDone,
      showNormalState,
      doActionClick,
    };
  },
};
</script>
