<template>
  <div class="projects-info-list item-list item-report-summary activity-table">
    <table>
      <thead>
        <tr>
          <th>Created</th>
          <th>Activity</th>
          <th>Involved Keys</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="project in projects"
          :key="project.key"
        >
          <td>
           {{ $filters.timeStamp(project.created_date) }}
          </td>
          <td>
            {{project.message}}
          </td>
          <td v-if="project.involved_keys">
            <template v-for="(values, prop) in project.involved_keys" :key="values.key">
              <template v-for="value in values" :key="value">
                <a target="blank"
                  v-if="prop === 'invoice'"
                  :href="consoleLink+ 'admin/invoice-list/'+value
                  +'/preview?search='+value">{{prop}} - {{value}}
                </a>
                <a target="blank"
                  v-else-if="prop === 'transaction'"
                  :href="consoleLink+ 'admin/transaction-list/'+value
                  +'/preview?search='+value">{{prop}} - {{value}}
                </a>
                <span v-else>
                  {{prop}} - {{value}}
                </span>
              </template>
            </template>
          </td>
          <td v-else>
            -
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Config from '@/config';

export default {
  props: {
    projects: Array,
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
};
</script>
<style lang="scss" scoped>
  .projects-info-list {
    &.item-list {
      &.item-report-summary {
        &.activity-table {
          table {
            width: calc(100% - #{size(60)});
            thead {
              tr {
                th {
                  padding-top: size(20);
                }
              }
            }
            tbody {
              tr {
                td {
                  font-size: size(15);
                  line-height: size(20);
                  padding: size(20) size(10);
                  a {
                    border-bottom: none;
                    display: block;
                    padding-bottom: size(8);
                  }
                }
                &:last-child {
                  td {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
